// File#: _2_modal-video
// Usage: codyhouse.co/license
(function() {
    var ModalVideo = function(element) {
      this.element = element;
      this.modalContent = this.element.getElementsByClassName('js-modal-video__content')[0];
      this.media = this.element.getElementsByClassName('js-modal-video__media')[0];
      this.contentIsIframe = this.media.tagName.toLowerCase() == 'iframe';
      this.modalIsOpen = false;
      this.initModalVideo();
    };
  
    ModalVideo.prototype.initModalVideo = function() {
      var self = this;
      // reveal modal content when iframe is ready
      this.addLoadListener();
      // listen for the modal element to be open -> set new iframe src attribute
      this.element.addEventListener('modalIsOpen', function(event){
        self.modalIsOpen = true;
        self.media.setAttribute('src', event.detail.closest('[aria-controls]').getAttribute('data-url'));
      });
      // listen for the modal element to be close -> reset iframe and hide modal content
      this.element.addEventListener('modalIsClose', function(event){
        self.modalIsOpen = false;
        Util.addClass(self.element, 'modal--is-loading');
        self.media.setAttribute('src', '');
      });
    };
  
    ModalVideo.prototype.addLoadListener = function() {
      var self = this;
      if(this.contentIsIframe) {
        this.media.onload = function () {
          self.revealContent();
        };
      } else {
        this.media.addEventListener('loadedmetadata', function(){
          self.revealContent();
        });
      }
      
    };
  
    ModalVideo.prototype.revealContent = function() {
      if( !this.modalIsOpen ) return;
      Util.removeClass(this.element, 'modal--is-loading');
      this.contentIsIframe ? this.media.contentWindow.focus() : this.media.focus();
    };
  
    //initialize the ModalVideo objects
    var modalVideos = document.getElementsByClassName('js-modal-video__media');
    if( modalVideos.length > 0 ) {
      for( var i = 0; i < modalVideos.length; i++) {
        (function(i){new ModalVideo(modalVideos[i].closest('.js-modal'));})(i);
      }
    }
  }());