// File#: _1_countup
// Usage: codyhouse.co/license
(function() {	
    var CountUp = function(opts) {
      this.options = Util.extend(CountUp.defaults , opts);
      this.element = this.options.element;
      this.initialValue = parseFloat(this.options.initial);
      this.finalValue = parseFloat(this.element.textContent);
      this.deltaValue = parseFloat(this.options.delta);
      this.intervalId;
      this.animationTriggered = false;
      // animation will run only on browsers supporting IntersectionObserver
      this.canAnimate = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
      initCountUp(this);
    };
  
    CountUp.prototype.reset = function() { // reset element to its initial value
      if(!this.canAnimate) return;
      window.cancelAnimationFrame(this.intervalId);
      this.element.textContent = this.initialValue;
    };  
  
    CountUp.prototype.restart = function() { // restart element animation
      countUpAnimate(this);
    };
  
    function initCountUp(countup) {
      // reset initial value
      countup.initialValue = getCountupStart(countup);
      if(!countup.canAnimate) { // IntersectionObserver not supported
        countUpShow(countup);
        return;
      }
  
      // reset countUp for SR
      initCountUpSr(countup);
  
      // listen for the element to enter the viewport -> start animation
      var observer = new IntersectionObserver(countupObserve.bind(countup), { threshold: [0, 0.1] });
      observer.observe(countup.element);
  
      // listen to events
      countup.element.addEventListener('countUpReset', function(){countup.reset();});
      countup.element.addEventListener('countUpRestart', function(){countup.restart();});
    };
  
    function countUpShow(countup) { // reveal countup after it has been initialized
      Util.addClass(countup.element.closest('.countup'), 'countup--is-visible');
    };
  
    function countupObserve(entries, observer) { // observe countup position -> start animation when inside viewport
      if(entries[0].intersectionRatio.toFixed(1) > 0 && !this.animationTriggered) {
        countUpAnimate(this);
      }
    };
  
    function countUpAnimate(countup) { // animate countup
      countup.element.textContent = countup.initialValue;
      countUpShow(countup);
      window.cancelAnimationFrame(countup.intervalId);
      var currentTime = null;
  
      function runCountUp(timestamp) {
        if (!currentTime) currentTime = timestamp;        
        var progress = timestamp - currentTime;
        if(progress > countup.options.duration) progress = countup.options.duration;
        var val = getValEaseOut(progress, countup.initialValue, countup.finalValue - countup.initialValue, countup.options.duration);
        countup.element.textContent = getCountUpValue(val, countup);
        if(progress < countup.options.duration) {
          countup.intervalId = window.requestAnimationFrame(runCountUp);
        } else {
          countUpComplete(countup);
        }
      };
  
      countup.intervalId = window.requestAnimationFrame(runCountUp);
    };
  
    function getCountUpValue(val, countup) { // reset new countup value to proper decimal places+separator
      if(countup.options.decimal) {val = parseFloat(val.toFixed(countup.options.decimal));}
      else {val = parseInt(val);}
      if(countup.options.separator) val = val.toLocaleString('en');
      return val;
    }
  
    function countUpComplete(countup) { // emit event when animation is over
      countup.element.dispatchEvent(new CustomEvent('countUpComplete'));
      countup.animationTriggered = true;
    };
  
    function initCountUpSr(countup) { // make sure countup is accessible
      // hide elements that will be animated to SR
      countup.element.setAttribute('aria-hidden', 'true');
      // create new element with visible final value - accessible to SR only
      var srValue = document.createElement('span');
      srValue.textContent = countup.finalValue;
      Util.addClass(srValue, 'sr-only');
      countup.element.parentNode.insertBefore(srValue, countup.element.nextSibling);
    };
  
    function getCountupStart(countup) {
      return countup.deltaValue > 0 ? countup.finalValue - countup.deltaValue : countup.initialValue;
    };
  
    function getValEaseOut(t, b, c, d) { 
      t /= d;
      return -c * t*(t-2) + b;
    };
  
    CountUp.defaults = {
      element : '',
      separator : true,
      duration: 3000,
      decimal: true,
      initial: 0,
      delta: 0
    };
  
    window.CountUp = CountUp;
  
    //initialize the CountUp objects
    var countUp = document.getElementsByClassName('js-countup');
    if( countUp.length > 0 ) {
      for( var i = 0; i < countUp.length; i++) {(function(i){
          var separator = (countUp[i].getAttribute('data-countup-sep')) ? countUp[i].getAttribute('data-countup-sep') : false,
          duration = (countUp[i].getAttribute('data-countup-duration')) ? countUp[i].getAttribute('data-countup-duration') : CountUp.defaults.duration,
          decimal = (countUp[i].getAttribute('data-countup-decimal')) ? countUp[i].getAttribute('data-countup-decimal') : false,
              initial = (countUp[i].getAttribute('data-countup-start')) ? countUp[i].getAttribute('data-countup-start') : 0,
          delta = (countUp[i].getAttribute('data-countup-delta')) ? countUp[i].getAttribute('data-countup-delta') : 0;
          new CountUp({element: countUp[i], separator : separator, duration: duration, decimal: decimal, initial: initial, delta: delta});
      })(i);}
    }
  }());